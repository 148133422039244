<template>
  <div class="mt-4">
    <div class="text-left " style="color: #757575;margin-top: -15px;background-color: #f2f3f7!important;z-index: 20">
      <span class="ml-2" @click="$router.push('/my-page')">My Page</span>
      <v-icon>mdi-chevron-right</v-icon>
      <span style="color: #39bf88">Question Bank</span>
    </div>
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-row style="background-color: #f2f3f7!important;height: 90px;" class="text-left pt-3">
      <v-col cols="4"
          class="text-capitalize question-titles pb-0 mb-0"
          @click="$router.push('/questions/new')">
        <span v-if="$route.path === '/questions/new'" style="color: #39bf88">
        New Test
        </span>
        <span v-else>
        New Test
        </span>
      </v-col>
      <v-col cols="4"
          class="text-capitalize question-titles pb-0 mb-0"
          @click="$router.push('/questions/prior')">
        <span v-if="$route.path === '/questions/prior'" style="color: #39bf88">
        Prior Tests
        </span>
        <span v-else>
                  Prior Tests
        </span>
      </v-col>
      <v-col cols="4"
          class="text-capitalize question-titles pb-0 mb-0"
          @click="$router.push('/questions/performance')">
        <span v-if="$route.path === '/questions/performance'" style="color: #39bf88">
        Performance
        </span>
        <span v-else>
                  Performance
        </span>

      </v-col>
      <v-col cols="4"
          class="text-capitalize question-titles"
          @click="$router.push('/questions/notes')">

        <span v-if="$route.path === '/questions/notes'" style="color: #39bf88">
        Notes
        </span>
        <span v-else>
                  Notes
        </span>
      </v-col>
      <v-col cols="4"
          class="text-capitalize question-titles"
          @click="$router.push('/questions/search')">
        <span v-if="$route.path === '/questions/search'" style="color: #39bf88">
        Search
        </span>
        <span v-else>
                  Search
        </span>
      </v-col>
      <v-col cols="4"
          class="text-capitalize question-titles"
          @click="$router.push('/questions/help')">
        <span v-if="$route.path === '/questions/help'" style="color: #39bf88">
        Help
        </span>
        <span v-else>
                  Help
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "QBHeaderMobile",
}
</script>

<style scoped>

.question-titles {
  font-size: 15px;
  color: #a8a8a8 !important
}

>>> .v-tabs-slider {
  background-color: unset !important;
}

>>> .v-tab:hover::before {
  opacity: 0 !important;
}

>>> .v-tab {
  letter-spacing: unset !important;
}

.question-title-active {
  color: #39bf88 !important
}
</style>
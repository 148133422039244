<template>
  <v-tabs
      show-arrows
      align-with-title
      color="#39bf88"
      height="85"
      style="margin-bottom: 65px!important;margin-top: -50px!important"
      background-color="#f2f3f7"
      centered
  >
    <v-tab
        class="text-capitalize question-titles"
        active-class="question-title-active"
        text
        plain
        to="/questions/new">
      New Test
    </v-tab>
    <v-tab
        class="text-capitalize question-titles"
        active-class="question-title-active"
        text
        plain
        to="/questions/prior">
      Prior Tests
    </v-tab>
    <v-tab
        class="text-capitalize question-titles"
        active-class="question-title-active"
        text
        plain
        to="/questions/performance">
      Performance
    </v-tab>
    <v-tab
        class="text-capitalize question-titles"
        active-class="question-title-active"
        text
        plain
        to="/questions/notes">
      Notes
    </v-tab>
    <v-tab
        class="text-capitalize question-titles"
        active-class="question-title-active"
        text
        plain
        to="/questions/search">
      Search
    </v-tab>
    <v-tab
        class="text-capitalize question-titles"
        active-class="question-title-active"
        text
        plain
        to="/questions/help">
      Help
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "QBHeader",
}
</script>

<style scoped>

.question-titles {
  font-size: 16px;
  color: #a8a8a8 !important
}

>>> .v-tabs-slider {
  background-color: unset !important;
}

>>> .v-tab:hover::before {
  opacity: 0 !important;
}

>>> .v-tab {
  letter-spacing: unset !important;
}

.question-title-active {
  color: #39bf88 !important
}
</style>
<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <div v-if="exam">
        <q-b-header></q-b-header>
        <div class="mb-16 pb-16">
          <v-row class="mt-16">
            <v-col class="text-center mt-16 pt-16">
              <img width="166px" height="142px" src="../assets/finish.svg" alt="finish exam">
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center finish-text">
              You have successfully finished the test!
            </v-col>
            <v-col cols="12" class="text-center">
              <v-row>
                <v-col class="text-center">
                  <v-btn rounded class="text-capitalize white--text mx-2 button-text" width="155px" height="60px"
                         elevation="0"
                         color="#39BF88" @click="goToReview()">
                    Review Test
                  </v-btn>
                  <v-btn rounded class="text-capitalize white--text mx-2 button-text" width="155px" height="60px"
                         elevation="0"
                         color="#FFBE66" @click="goToResults()">
                    See Results
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-overlay
            :z-index="zIndex"
            :value="overlayEye"
        >
          <v-row>
            <v-col cols="3">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="7">
                      Score:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="#39bf88">{{ viewItemsExtra.score }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols=" viewItemsExtra.mode !== 'template-exam' ?  7 : 5">
                      Mode:
                    </v-col>
                    <v-col :cols=" viewItemsExtra.mode !== 'template-exam' ?  5 : 7">
                      <v-btn x-small color="#f1803f" v-if="viewItemsExtra.mode === 'time'">timed</v-btn>
                      <v-btn x-small color="#f1803f" v-else-if="viewItemsExtra.mode === 'tutor'">Tutor</v-btn>
                      <v-btn x-small color="#f1803f" v-else-if="viewItemsExtra.mode === 'liveTutor'">Live Tutor</v-btn>
                      <v-btn x-small color="#f1803f" v-else-if="viewItemsExtra.mode === 'liveTutor'">Live Tutor</v-btn>
                      <v-btn x-small color="#f1803f" v-else-if="viewItemsExtra.mode === 'template-exam'">Assessment</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7">
                      Correct:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="#39bf88">{{ viewItemsExtra.correct }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7">
                      Incorrect:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="red">{{ viewItemsExtra.incorrect }}</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="9">
              <v-card>
                <v-card-actions>
                  <v-row>
                    <v-col md="11" sm="10"></v-col>
                    <v-col md="1" sm="2">
                      <a @click="overlayEye=!overlayEye">
                        <v-icon color="red">mdi-close</v-icon>
                      </a>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <v-card-text>
                  <v-data-table
                      :headers="viewHeaders"
                      :items="viewItems"
                      :items-per-page="5"
                      class="elevation-1"
                  >
                    <template v-slot:item.questions="{item}">
                      <a v-if="item.correct"><span class="mr-3"
                                                   style="font-family: Blinker-SemiBold!important;font-size:20px">{{
                          item.questions
                        }}</span>
                        <v-icon color="#39bf88">mdi-check</v-icon>
                      </a>
                      <a v-else><span class="mr-3"
                                      style="font-family: Blinker-SemiBold!important;font-size:20px">{{
                          item.questions
                        }}</span>
                        <v-icon color="red">mdi-close</v-icon>
                      </a>
                    </template>
                    <template v-slot:item.seen="{item}">
                      <a>
                        <v-icon class="icon-view-hover" color="#39bf88" @click="showQuestion(item.id)">mdi-eye</v-icon>
                      </a>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-overlay>
        <v-overlay
            :z-index="zIndex2"
            :value="overlayEye2"
        >
          <v-row>
            <v-col cols="8" offset="2">
              <v-card>
                <v-card-actions>
                  <v-row>
                    <v-col md="11" sm="10"></v-col>
                    <v-col md="1" sm="2">
                      <a @click="closeQuestion()">
                        <v-icon color="red">mdi-close</v-icon>
                      </a>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <v-divider></v-divider>
                <div v-if="singleQuestion.question">
                  <v-card-title class="px-16">
                    Question : {{ ' ' + singleQuestion.question['title'] }}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row>
                      <v-col cols="1"></v-col>
                      <v-col cols="10">
                        <v-row class="my-8">
                          <span style="font-size:19px">Answers:</span>
                        </v-row>
                        <v-row class="my-8">
                          <v-col cols="12" class="text-left" v-for=" (answer,index) in singleQuestion.answers" :key="answer.id">
                            <v-row>
                              <v-col cols="2">
                                {{ index+1 }}
                                <span class="ml-2" v-if="singleQuestion.userAnswerId === answer['id'] "><v-icon>mdi-radiobox-marked</v-icon></span>
                                <span class="ml-2" v-else><v-icon color="white">mdi-radiobox-blank</v-icon></span>
                              </v-col>
                              <v-col cols="10">
                                <p v-if="answer['is_true']" style="color:green;font-size:20px">
                                  <span v-html="answer['content']"></span>
                                  <span class="mr-1" v-if="singleQuestion.userAnswerId === answer['id'] "
                                        style="color: #f1803f">(You have chosen this answer)</span>
                                </p>
                                <p v-else style="color:red;font-size:20px">
                                  <span v-html="answer['content']"></span>
                                  <span class="mr-1" v-if="singleQuestion.userAnswerId === answer['id'] "
                                        style="color: #f1803f">(You have chosen this answer)</span>
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="1"></v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-subtitle>
                    <v-row>
                      <v-col md="2" sm="4">
                        <a @click="closeQuestion">
                          <v-icon large>mdi-arrow-left</v-icon>
                        </a>
                      </v-col>
                      <v-col md="10" sm="8">
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-overlay>
      </div>
    </div>
    <div v-else>
      <div v-if="exam">
        <q-b-header-mobile></q-b-header-mobile>
        <div class="mb-16 pb-16">
          <v-row class="mt-16">
            <v-col class="text-center mt-16 pt-16">
              <img width="166px" height="142px" src="../assets/finish.svg" alt="finish exam">
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center finish-text">
              You have successfully finished the test!
            </v-col>
            <v-col cols="12" class="text-center">
              <v-row>
                <v-col class="text-center">
                  <v-btn rounded class="text-capitalize white--text mx-2 button-text" width="155px" height="60px"
                         elevation="0"
                         color="#39BF88" @click="goToReview()">
                    Review Test
                  </v-btn>
                  <v-btn rounded class="text-capitalize white--text mx-2 button-text" width="155px" height="60px"
                         elevation="0"
                         color="#FFBE66" @click="goToResults()">
                    See Results
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-overlay
            :z-index="zIndex"
            :value="overlayEye"
            style="width: 100%!important;"
        >
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="7">
                      Score:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="#39bf88">{{ viewItemsExtra.score }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols=" viewItemsExtra.mode !== 'template-exam' ?  7 : 5">
                      Mode:
                    </v-col>
                    <v-col :cols=" viewItemsExtra.mode !== 'template-exam' ?  5 : 7">
                      <v-btn x-small color="#f1803f" v-if="viewItemsExtra.mode === 'time'">timed</v-btn>
                      <v-btn x-small color="#f1803f" v-else-if="viewItemsExtra.mode === 'tutor'">Tutor</v-btn>
                      <v-btn x-small color="#f1803f" v-else-if="viewItemsExtra.mode === 'liveTutor'">Live Tutor</v-btn>
                      <v-btn x-small color="#f1803f" v-else-if="viewItemsExtra.mode === 'liveTutor'">Live Tutor</v-btn>
                      <v-btn x-small color="#f1803f" v-else-if="viewItemsExtra.mode === 'template-exam'">Assessment</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7">
                      Correct:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="#39bf88">{{ viewItemsExtra.correct }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7">
                      Incorrect:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="red">{{ viewItemsExtra.incorrect }}</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-actions>
                  <v-row>
                    <v-col md="11" sm="10"></v-col>
                    <v-col md="1" sm="2">
                      <a @click="overlayEye=!overlayEye">
                        <v-icon color="red">mdi-close</v-icon>
                      </a>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <v-card-text>
                  <v-data-table
                      :headers="viewHeaders"
                      :items="viewItems"
                      :items-per-page="5"
                      class="elevation-1"
                  >
                    <template v-slot:item.questions="{item}">
                      <a v-if="item.correct"><span class="mr-3"
                                                   style="font-family: Blinker-SemiBold!important;font-size:20px">{{
                          item.questions
                        }}</span>
                        <v-icon color="#39bf88">mdi-check</v-icon>
                      </a>
                      <a v-else><span class="mr-3"
                                      style="font-family: Blinker-SemiBold!important;font-size:20px">{{
                          item.questions
                        }}</span>
                        <v-icon color="red">mdi-close</v-icon>
                      </a>
                    </template>
                    <template v-slot:item.seen="{item}">
                      <a>
                        <v-icon class="icon-view-hover" color="#39bf88" @click="showQuestion(item.id)">mdi-eye</v-icon>
                      </a>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-overlay>
        <v-overlay
            :z-index="zIndex2"
            :value="overlayEye2"
        >
          <v-row>
            <v-col cols="8" offset="2">
              <v-card>
                <v-card-actions>
                  <v-row>
                    <v-col md="11" sm="10"></v-col>
                    <v-col md="1" sm="2">
                      <a @click="closeQuestion()">
                        <v-icon color="red">mdi-close</v-icon>
                      </a>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <v-divider></v-divider>
                <div v-if="singleQuestion.question">
                  <v-card-title class="px-16">
                    Question : {{ ' ' + singleQuestion.question['title'] }}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row>
                      <v-col cols="1"></v-col>
                      <v-col cols="10">
                        <v-row class="my-8">
                          <span style="font-size:19px">Answers:</span>
                        </v-row>
                        <v-row class="my-8">
                          <v-col cols="12" class="text-left" v-for=" (answer,index) in singleQuestion.answers" :key="answer.id">
                            <v-row>
                              <v-col cols="2">
                                {{ index+1 }}
                                <span class="ml-2" v-if="singleQuestion.userAnswerId === answer['id'] "><v-icon>mdi-radiobox-marked</v-icon></span>
                                <span class="ml-2" v-else><v-icon color="white">mdi-radiobox-blank</v-icon></span>
                              </v-col>
                              <v-col cols="10">
                                <p v-if="answer['is_true']" style="color:green;font-size:20px">
                                  <span v-html="answer['content']"></span>
                                  <span class="mr-1" v-if="singleQuestion.userAnswerId === answer['id'] "
                                        style="color: #f1803f">(You have chosen this answer)</span>
                                </p>
                                <p v-else style="color:red;font-size:20px">
                                  <span v-html="answer['content']"></span>
                                  <span class="mr-1" v-if="singleQuestion.userAnswerId === answer['id'] "
                                        style="color: #f1803f">(You have chosen this answer)</span>
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="1"></v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-subtitle>
                    <v-row>
                      <v-col md="2" sm="4">
                        <a @click="closeQuestion">
                          <v-icon large>mdi-arrow-left</v-icon>
                        </a>
                      </v-col>
                      <v-col md="10" sm="8">
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-overlay>
      </div>
    </div>
  </div>

</template>

<script>
import QBHeader from "../components/QBHeader";
import QBHeaderMobile from "../components/QBHeaderMobile.vue";

export default {
  name: "QuestionsFinished",
  components: {
    QBHeaderMobile,
    QBHeader,
  },
  data() {
    return {
      screenType: null,
      exam: null,
      zIndex: 5,
      zIndex2: 9,
      viewItemsExtra: [],
      viewItems: [],
      overlayEye: false,
      overlayEye2: false,
      singleQuestion: [],
      viewHeaders: [
        {
          text: 'Questions',
          align: 'start',
          sortable: true,
          value: 'questions',
        },
        {text: 'Chapter', value: 'chapter'},
        {text: 'Section', value: 'section'},
        {text: 'Topic', value: 'topic'},
        {text: 'Seen', value: 'seen'},
      ],
    }
  },
  mounted() {
    this.getScreenType()
    this.exam = this.$route.params.exam
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    closeQuestion() {
      this.overlayEye = true
      this.overlayEye2 = false
    },
    showQuestion(id) {
      this.overlayEye = false
      this.overlayEye2 = true
      window.axios.get('api/exam/question/' + id).then((res) => {
        this.singleQuestion = res.data.data
      })
    },
    goToReview() {
      this.$router.push({
        name: 'ReviewTest',
        params: this.exam
      })
    },
    goToResults() {
      window.axios.get('api/exam/passed/detail/' + this.exam.id).then((res) => {
        this.viewItems = res.data.data.data
        this.viewItemsExtra['score'] = res.data.data.score
        this.viewItemsExtra['mode'] = res.data.data.mode
        this.viewItemsExtra['correct'] = res.data.data.correct
        this.viewItemsExtra['incorrect'] = res.data.data.incorrect
      })
      this.overlayEye = true
    }
  },
}
</script>

<style scoped>
@font-face {
  font-family: 'Blinker-Bold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Regular';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}

.finish-text {
  color: #A8A8A8;
  font-size: 20px;
}

.button-text {
  font-size: 16px;
}
</style>